<template>
  <div class="">
    <div class="user-flow__logo">
      <img
        src="~@/assets/excelerate-logo.png"
        alt="Excelerate"
        title="Excelerate"
      />
    </div>
    <div class="user-flow" v-if="isLoaded">
      <div class="user-splash"></div>
      <div class="row">
        <div class="col-12 user-form">
          <form @submit.prevent="updateProfessional" v-if="step === 1">
            <h2 >
              WELCOME TO EXCELERATE
            </h2>
            <h4
              class="user-steps text-center"
            >
              STEP 2 OUT OF {{ maxStep }}
            </h4>
            <p class="text-center">
              Tell us a bit more about yourself to get started on Excelerate
            </p>
            <div class="form-group">
              <label for="">First name *</label>
              <input
                type="text"
                class="form-control form-control-lg"
                placeholder="Enter your first name"
                v-model="userInfo.firstName"
                autofocus
                required
              />
            </div>
            <div class="form-group">
              <label for="">Last name *</label>
              <input
                type="text"
                class="form-control form-control-lg"
                v-model="userInfo.lastName"
                placeholder="Enter your last name"
                required
              />
            </div>
            <div>
              <div
                class="form-group"
              >
                <label for="">Phone number *</label>
                <input
                  type="phone"
                v-model="userInfo.phoneNumber"
                  class="form-control form-control-lg"
                  placeholder="Enter your phone number"
                />
              </div>
            </div>
            <div>
              <div class="form-group">
                <label for="">Company</label>
                <input
                  type="text"
                  class="form-control form-control-lg"
                  placeholder="Select your company or add new"
                v-model="userInfo.company"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <label for=""
                >What is your current position in the company? *</label
              >
              <select
                class="form-control"
                v-model="userInfo.designation"
              >
                <option value="" selected disabled>Select from list</option>
                <option
                  v-for="(role, index) in roles"
                  :key="index"
                  :value="role"
                >
                  {{ role }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <div class="custom-control custom-checkbox mb-1">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  name="role"
                  id="signupTerms"
                  v-model="terms"
                  value="terms"
                  required
                />
                <label class="custom-control-label" for="signupTerms"
                  >I accept
                  <router-link to="/user/terms" class="color-third"
                    >the terms and conditions</router-link
                  ></label
                >
              </div>
            </div>
            <div class="user-form-buttons">
              <button
                class="btn btn-primary ml-2"
              >
                Next
              </button>
            </div>
          </form>
          <form v-if="this.step === 3" class="text-center">
            <h1 class="text-uppercase common-text">CONGRATULATIONS</h1>
            <h4 class="welcome-desc font-weight-normal">
              YOUR PROFILE HAS BEEN CREATED
            </h4>
            <i class="fa fa-check check-icon" aria-hidden="true"></i>
            <p class="">
              Our account team will contact you shortly to discuss the next
              steps to get your company closer to talents. <br />
              <br />
              Login is disabled for now.
            </p>
            <button disabled class="btn btn-dark w-200">Login</button>
          </form>
          <template v-if="step === 2">
            <form @submit.prevent="verifyCode">
              <h2 class="text-center">VERIFY YOUR PROFILE</h2>
              <h4
                class="user-steps text-center"
              >
                STEP 3 OUT OF {{ maxStep }}
              </h4>
              <p class="text-center">
                We have sent a 6-digit code to your email.
                <br />
                Remember to check you spam folder
              </p>
              <div class="form-group text-left mb-0">
                <label>Activate your profile</label>
                <input
                  type="text"
                  v-model="verificationCode"
                  name="code"
                  class="form-control form-control-lg"
                  placeholder="Enter 6-digit code"
                  autofocus
                />
                <span class="text-error">{{  }}</span>
                <div
                  class="alert alert-success mt-3"
                  v-if="verifyMessageSuccess"
                >
                  {{ verifyMessageSuccess }}
                </div>
                <div class="alert alert-danger mt-3" v-if="errorMessage">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="text-right">
                <button class="btn btn-link" @click.prevent="resendVerificationEmail()">
                  Resend code
                </button>
              </div>
              <div class="user-form-buttons">
                <button
                  class="btn btn-primary ml-2"
                >
                  Complete sign up
                </button>
              </div>
            </form>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import _ from 'lodash'
import '@trevoreyre/autocomplete-vue/dist/style.css';
import { mapState } from 'vuex';
import { base64ToString } from '../../utils/utils';

const user_id = base64ToString(localStorage.getItem('uid'))

export default {
  data: function () {
    return {
      userInfo: {},
      differentStructure: false,
      step: 1,
      maxStep: 3,
      verificationCode: '',
      terms: false,
      roles: [
        'CEO',
        'CHRO',
        'CPO',
        'Other C-Level',
        'HR Director',
        'HR Manager',
        'HR Specialist',
        'HR Assistant',
        'Recruiter',
        'Manager',
        'Team-Lead',
        'Other',
      ],
    };
  },
  metaInfo: {
    title: 'Welcome to Excelerate',
    meta: [
      {
        name: 'description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
      { property: 'og:title', content: 'Welcome to Excelerate.' },
      {
        property: 'og:description',
        content:
          'A digital platform that connects students and companies via thesis collaboration matching.',
      },
    ],
  },
  mounted: function () {
    // if() // check for professional signup data
    this.$store.dispatch('getProfessionalSignupData', 'Professional');
    if (!this.user.professional) {
      this.$store.dispatch('getProfessionalData')
    }
  },
  computed: {
    ...mapState(['user']),
    isLoaded: function () {
      const vm = this
      const userType = base64ToString(localStorage.getItem('i_am'))
      if(vm.user.professional) {
        vm.userInfo = vm.user.professional
      }
      return true
    },
    errorMessage(){
      return this.$store.getters.userErrorMessage
    }
  },
  methods: {
    previousStep: function () {
      this.step--;
    },
    nextStep: function () {
      this.step++;
    },
    resendVerificationEmail() {
      var vm = this;
      const verificationEmailData = {
        email: this.userInfo.email,
      };
      vm.$store
        .dispatch('resendVerificationCode', verificationEmailData)
        .then(() => {
          vm.verifyMessageSuccess = 'Check your inbox';
        }).catch((err) => console.log(err))
    },
    updateProfessional(){
      this.$store.dispatch('updateProfessional', this.userInfo).then(() => this.nextStep())
    },
    verifyCode: function () {
      var vm = this;

    //   this.verifyMessageSuccess = '';
    //   this.verifyMessageError = '';
    //   // var userVerificationCode = vm.userDetails.verificationCode; // why was this not updated
      this.$store.dispatch('verifyProfessional', {otp: this.verificationCode}).then((r) => {
        if(r) {
          this.nextStep()
          localStorage.removeItem('uid');
          localStorage.removeItem('i_am');
          localStorage.removeItem('user_code');
          localStorage.removeItem('auth-token');
        }
      })
    //   if (parseInt(vm.userDetails.verificationCode) === parseInt(inputCode)) {
    //     vm.verifyMessageSuccess = 'Code accepted';
    //     this.userDetails.status = 'verified'
    //     vm.updateUserDetails();
    //     vm.$store.dispatch('updateUserVerification', true);
    //   } else {
    //     vm.verifyMessageError =
    //       'No match. Please check your code and try again.';
    //   }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__option--highlight {
  background: #bd1a77;
  outline: none;
  color: #fff;
}
</style>
<style scoped> 
.check-icon{
  color: #bd1a77;
  font-size: 100px;
  margin: 50px 0;
}
.user-flow__logo{
  padding:40px 0;
  text-align: center;
}
</style>
